import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Field, reduxForm } from 'redux-form';
import Modal from 'react-modal';

import loader from 'images/loader.gif';
import { withTranslation } from 'react-i18next';
import { modalCss } from 'utils/modalCss';
import GooglePlacesSearchBox from 'components/GooglePlacesSearchBox';
import { createHub } from '../../store/hubs/hubActions';

import './createHub.scss';

class CreateHubStepOne extends React.Component {
  renderForm() {
    const {
      organizationTypes,
      onSubmit,
      match,
      hubName,
      isRequesting,
      t,
    } = this.props;
    return (
      <Modal
        isOpen
        contentLabel={t('CreateHubModal')}
        onRequestClose={() => window.history.back()}
        shouldCloseOnOverlayClick={false}
        style={modalCss}
      >
        <form
          className="createHub"
          onSubmit={(e) => onSubmit(e, match.params.h_id)}
        >
          <h2 className="createHub__title">
            {t('CreateYourHub')} - {hubName}
          </h2>
          <div className="required-prompt">{t('*required')}</div>
          <div className="createHub__question">
            <span className="createHub__label required">{t('Location')}</span>
            <div>
              <Field
                label="location_map"
                name="location_map"
                className="createHub__field"
                component={GooglePlacesSearchBox}
                autocompletionRequest={{ types: ['(cities)'] }}
                placeholder={t('LocationPlaceholder')}
              />
            </div>
          </div>
          <div className="createHub__question">
            <span className="createHub__label required">{t('Type')}</span>
            <div>
              <Field
                name="organization_type_id"
                component="select"
                className="createHub__field"
              >
                <option selected disabled hidden value="">
                  {t('SelectType')}
                </option>
                {organizationTypes.map((ot) => (
                  <option key={ot.id} value={ot.id}>
                    {ot.label}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="createHub__question">
            <span className="createHub__label">{t('Website')}</span>
            <Field
              component="input"
              type="text"
              name="website"
              className="createHub__field"
              placeholder="https://www.hub-name.com"
            />
          </div>
          <div className="btn-wrap">
            <span className="createHub__pageIndex">1/2</span>
            {isRequesting ? (
              <button className="btn--saving disabled" disbaled>
                <img src={loader} alt="loading" /> {t('Working')}
              </button>
            ) : (
              <button className="btn-main" type="submit">
                {t('Next')} &gt;
              </button>
            )}
          </div>
        </form>
      </Modal>
    );
  }

  render() {
    return this.renderForm();
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmit(e, hubId) {
    e.preventDefault();
    dispatch(createHub(hubId));
  },
});

function mapStateToProps(state) {
  const { appData, metadata, invitations } = state;
  const { organizationTypes } = appData;

  const processingHubInvitation = invitations.createHubInvitations.find(
    (chi) => chi.get('token') === invitations.createHubInvitationToken
  );

  return {
    organizationTypes: organizationTypes.sortBy((ot) => ot.get('label')).toJS(),
    hubName: processingHubInvitation.get('hub_name'), // hubs.userHubs.find(h => h.get('id') === parseInt(props.match.params.h_id, 10)).get('name'),
    isRequesting: metadata.isRequesting,
  };
}

export default withTranslation(['translation.Hub']) (withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({ form: 'CreateHubForm', enableReinitialize: true })(
      CreateHubStepOne
    )
  )
));
