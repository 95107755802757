import { errorPopup } from 'functions/popup/errorPopup';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FaImage } from 'react-icons/fa';
import { connect } from 'react-redux';

class CustomImageInput extends Component {
  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.state = {
      file: undefined,
      imagePreviewUrl: undefined,
    };
    if (typeof this.props.inputRef === 'function') this.props.inputRef(this);
  }

  handleImageChange(e) {
    const { setFieldValue, field } = this.props;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (
      this.props.imageType === 'hubLogo' &&
      file.size &&
      file.size > 5242880
    ) {
      return this.props.maxSizeErrorPopup('ImageSizeLimit');
    }

    if (
      this.props.imageType === 'activityImage' &&
      file.size &&
      file.size > 3145728
    ) {
      return this.props.maxSizeErrorPopup('ImageSizeLimit3mb');
    }

    const validImageTypes = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/gif',
    ];

    if (file && !validImageTypes.includes(file.type)) {
      return this.props.triggerImageErrorPopup();
    }
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      setFieldValue(field.name, file);
    }
    return true;
  }

  showFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.current.click();
    }
  }

  showPreloadImage() {
    const { field, imageType, project } = this.props;
    const { value } = field;
    const { file, imagePreviewUrl } = this.state;

    let comp = null;

    if (imageType === 'avatar-upload' || imageType === 'activityImage')
      return comp;
    if (file) {
      comp = (
        <img
          style={{
            width: '100%',
            maxWidth: '200px',
            maxHeight: '200px',
            objectFit: 'cover',
          }}
          src={imagePreviewUrl}
          alt="..."
        />
      );
    } else if (value) {
      comp = <img style={{ width: '100%' }} src={value} alt="..." />;
    } else if (imageType === 'backdrop' && project.backdrop_url) {
      comp = (
        <img
          style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }}
          src={project.backdrop_url}
          alt="backdrop"
        />
      );
    } else if (imageType === 'logo' && project.logo_url) {
      comp = (
        <img
          src={project.logo_url}
          alt="logo"
          style={{
            width: '100%',
            maxWidth: '200px',
            maxHeight: '200px',
            objectFit: 'cover',
          }}
        />
      );
    } else comp = <FaImage size={70} style={{ width: '100%' }} />;

    return comp;
  }

  render() {
    const { errorMessage, title, field, imageType, t } = this.props;
    const { name } = field;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <input
          id={name}
          name={name}
          type="file"
          onChange={this.handleImageChange}
          ref={this.fileUpload}
          accept=".png, .jpg, .jpeg, .gif, .jfif"
        />
        <div role="presentation" onClick={this.showFileUpload} className={name}>
          {this.showPreloadImage(t)}
        </div>
        {!imageType && <h5>{title}</h5>}

        {imageType === 'backdrop' && (
          <label className="image-upload__upload-btn" htmlFor={imageType}>
            {t('selectImage')}
          </label>
        )}
        {imageType === 'logo' && (
          <label id="img-upload" htmlFor={imageType}>
            <span className="image-upload__upload-btn">{t('uploadLogo')}</span>
          </label>
        )}
        {imageType === 'hubLogo' && (
          <label htmlFor={imageType} className="btn-link">
            {t('selectLogo')}
          </label>
        )}
        {errorMessage ? (
          <div variant="caption" color="error">
            {errorMessage}
          </div>
        ) : null}
      </div>
    );
  }
}

CustomImageInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  imageType: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Object),
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  triggerImageErrorPopup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CustomImageInput.defaultProps = {
  project: {},
  errorMessage: '',
  title: '',
};

function mapDispatchToProps(dispatch, { t }) {
  return {
    triggerImageErrorPopup: () => dispatch(errorPopup(t('SupportedFormats'))),
    maxSizeErrorPopup: (phrase) =>
      dispatch(errorPopup(t(phrase || 'ImageSizeLimit'))),
  };
}

export default withTranslation(['translation.forms'])(
  connect(null, mapDispatchToProps)(CustomImageInput)
);
